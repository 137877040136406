import {CustomCssVarsFnParams} from '../../../../baseCustomCssVars';
import {SliderArrowsPlacement} from '../../../../constants';

/* istanbul ignore file */
export const sliderGalleryTitleCssVars = ({styleParams: styles}: CustomCssVarsFnParams) => {
  const getTitlePadding = () => {
    const arrowsOnImage = styles.numbers.gallery_sliderArrowsPlacement === SliderArrowsPlacement.Inside;
    const showSliderNavigationArrows = styles.booleans.gallery_showSliderNavigationArrows;
    const distanceBetweenArrowAndSlider = styles.numbers.gallery_sliderNavigationArrowsSpacing;
    const productItemBorderWidth = Number(styles.strings.gallery_borderWidth?.value) || 0;

    const arrowBorderWidth = styles.numbers.gallery_sliderArrowsContainerBorderWidth;
    const arrowsWidth = styles.numbers.gallery_sliderArrowsSize;
    const arrowsOffset = distanceBetweenArrowAndSlider + productItemBorderWidth;
    const shouldHaveArrowsPadding = showSliderNavigationArrows && !arrowsOnImage;
    return shouldHaveArrowsPadding ? `${arrowBorderWidth + arrowsWidth + arrowsOffset}px` : 0;
  };

  return {
    sliderTitlePadding: getTitlePadding(),
  };
};
